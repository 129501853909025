import { Title, Card, Image, Table } from 'reactive-bulma';
import teams from './teams.json';
import matches from './matches.json';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldDog, faShieldHalved } from '@fortawesome/free-solid-svg-icons';
import { minicopa, intersite } from './cup';


export const tabStyle = {
    "alignItems": "center",
    "display": "flex",
    "justifyContent": "center",
    "marginBottom": "-1px",
    "padding": ".5em 1em",
    "verticalAlign": "top",
}

export const Matches = () => {
    const [isMobile] = useState(!window.matchMedia("(min-width: 768px)").matches);
    const [watching, setWatching] = useState();
    const [activeTab, setActiveTab] = useState("matches");

    const food = (<>
        <div class="select is-link">
            <select
                onChange={e => setWatching(e.target.value)}
            >
                <option>Selecciona a tu equipo</option>
                {teams.map(team => 
                    <option value={team.teamName}>{team.teamName}</option>
                )}
            </select>
        </div>

        <Card
            cssClasses='mt-3'
            content={<div className='columns'>
                {watching && <div className='column is-one-quarter'>
                    <Title 
                        main={{
                            text: watching,
                            type: "subtitle"
                        }
                    }/>
                    <Image src={`/assets/teams/${watching}.png`}
                        style={{
                            height:"auto",
                            maxHeight: "10rem",
                            width:"auto",
                            margin:"auto",
                        }}
                    />
                </div>}
                <Table
                    cssClasses=''
                    body={[
                        { 
                            isSelected: false,
                            listOfCells: [
                                //{ content: watching },
                                { content: 'Comida' },
                                { content: 'Consúltalo al terminar cada partido' }
                            ]
                        },
                        { 
                            isSelected: false,
                            listOfCells: [
                                //{ content: '' },
                                { content: 'Actividad de banderas' },
                                { content: 'Consúltalo al terminar cada partido' }
                            ]
                        },
                        { 
                            isSelected: false,
                            listOfCells: [
                                //{ content: '' },
                                { content: 'Conexión Hamable' },
                                { content: 'Consúltalo al terminar cada partido' }
                            ]
                        },
                    ]}
                    head={[
                        //{ content: 'Equipo' },
                        { content: 'Actividad' },
                        { content: 'Horario' },
                    ]}
                />
            </div>}
            headerText="Comida y otras actividades"
        />
    </>);

    return(<div className="has-text-dark" style={{
        position: "absolute",
        width:"100%",
        top: isMobile ? "11rem":"8rem",
        left:"0",
        color: "#000",
        textAlign:"left"
    }}>
        <div className='container'>
            <Title
                main={{
                    text: 'Partidos',
                    type: 'title'
                }}
            />
            
            <div class="tabs">
                <ul>
                    <li
                        className={activeTab === "matches" && 'is-active'} 
                        style={{ borderBottom: "1px solid", color: activeTab === "matches" ? "#4e73bb" : "#4a4a4a" }}
                        onClick={() => setActiveTab('matches')}
                    >
                        <span style={tabStyle}>Partidos anunciados</span>
                    </li>
                    
                    <li
                        className={activeTab === "schedule" && 'is-active'} 
                        style={{ borderBottom: "1px solid", color: activeTab === "schedule" ? "#4e73bb" : "#4a4a4a" }}
                        onClick={() => setActiveTab('schedule')}
                    >
                        <span style={tabStyle}>Horarios</span>
                    </li>

                    <li
                        className={activeTab === "food" && 'is-active'} 
                        style={{ borderBottom: "1px solid", color: activeTab === "food" ? "#4e73bb" : "#4a4a4a" }}
                        onClick={() => setActiveTab('food')}
                    >
                        <span style={tabStyle}>Comida y otras actividades</span>
                    </li>
                </ul>
            </div>

            {activeTab === "matches" && <div className='columns is-multiline mt-5'>
                {matches.map(match => (
                    <div 
                        className='column is-one-third'
                    >
                        <Card
                            style={{ cursor:"pointer" }}
                            cssClasses="has-text-dark"
                            headerText={<span>{match.date} {match.time} <span class="tag is-light is-success mb-1">Torneo</span><br/> <span class="tag is-light is-info">{match.teamA}</span> vs <span class="tag is-light is-info">{match.teamB}</span></span>}
                            content={(<>
                                <b>Lugar:</b> Cancha {match.field}
                                <div className='columns has-text-centered'>
                                    <div className='column'>
                                        <Image src={`/assets/teams/${match.teamA}.png`}
                                            style={{
                                                height:"auto",
                                                maxHeight: "7rem",
                                                width:"auto",
                                                margin:"auto",
                                            }}
                                        />
                                    </div>
                                    <div className='column'>
                                        <Image src={`/assets/teams/${match.teamB}.png`}
                                            style={{
                                                height:"auto",
                                                maxHeight: "7rem",
                                                width:"auto",
                                                margin:"auto",
                                            }}
                                        />
                                    </div>
                                </div>
                                {/* <Image src={`/assets/shirts/${match.teamName}.jpeg`}
                                    style={{
                                        maxHeight: "80vh",
                                        width:"auto",
                                        margin:"auto",
                                    }}
                                /> */}
                            </>)}
                        />
                    </div>
                ))}
            </div>}

            {activeTab === "schedule" && <div className=''>
                {intersite} <br/>
                {minicopa}
            </div>
            }
            
            {activeTab === "food" && <div className=''>
                {food}
            </div>}
        </div>
    </div>);
}