import { Title, Card, Image, Table } from 'reactive-bulma';
import teams from './teams.json';
import players from './players.json';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faImage, faMap, faUpload } from '@fortawesome/free-solid-svg-icons';
import { tabStyle } from './matches';
import { Gallery } from '../../../components/gallery';
import Img1 from '../../../assets/fmf/1.jpg';
import Img2 from '../../../assets/fmf/2.jpeg';
import Img3 from '../../../assets/fmf/3.jpeg';

export const GalleryView = () => {
    const [isMobile] = useState(!window.matchMedia("(min-width: 768px)").matches);
    const [activeTab, setActiveTab] = useState("gallery");
    const galleryContent = [
        // Img1,
        // Img2,
        // Img3,
    ];

    return(<div className="has-text-dark" style={{
        position: "absolute",
        width:"100%",
        top: isMobile ? "11rem":"8rem",
        left:"0",
        color: "#000",
        textAlign:"left"
    }}>
        <div className='container'>
            <Title
                main={{
                    text: "En este espacio subiremos las fotografías del evento en tiempo real.",
                    type: "title"
                }}
            />

            <div class="tabs">
                <ul style={{ borderBottom: "1px solid #ddd" }}>
                    <li
                        className={activeTab === "gallery" && 'is-active'} 
                        style={{ color: activeTab === "gallery" ? "#3e8ed0" : "#4a4a4a" }}
                        onClick={() => setActiveTab('gallery')}
                    >
                        <span style={activeTab === "gallery" ? {...tabStyle, borderBottom:"1px solid #3e8ed0" } : tabStyle}>
                            <FontAwesomeIcon style={{ fontSize:"1.3rem", margin:"0 1rem" }} icon={faImage} />    
                            Galería
                        </span>
                    </li>

                    {false && <li
                        className={activeTab === "upload" && 'is-active'} 
                        style={{ color: activeTab === "upload" ? "#3e8ed0" : "#4a4a4a" }}
                        onClick={() => setActiveTab('upload')}
                    >
                        <span style={activeTab === "upload" ? {...tabStyle, borderBottom:"1px solid #3e8ed0" } : tabStyle}>
                            <FontAwesomeIcon style={{ fontSize:"1.3rem", margin:"0 1rem" }} icon={faUpload} />
                            Cargar Imagen
                        </span>
                    </li>}
                </ul>
            </div>

            {activeTab === "upload" && <div style={{ color: "#000", paddingTop:"6rem", textAlign:"center", margin:"auto", width: "60rem", height:"25rem", border:"5px dashed #bbb" }}>
                <Title
                    main={{
                        text: 'Arrastre y suelte sus archivos aquí',
                        type: 'title'
                    }}
                    secondary={{
                        text: 'jpg, png, jpeg',
                        type: 'subtitle'
                    }}
                />
            </div>}

            {activeTab === "gallery" && <Gallery content={galleryContent}/>}
        </div>
    </div>);
}