import { Hero, Title, Card, FormField, Button } from 'reactive-bulma'
import { BrandScreen } from '../../components/brand-screen';
import { useState } from 'react';
import { Roles } from '../../etc/roles';

export const HomePage =  ()=> {
    const [credentials, setCredentials] = useState({});
    const [helper, setHelper] = useState({});



    const credentialsList =[
        {
            "name": "Monserrat",
            "user": "monher0406@gmail.com",
            "password": "google123",
            "role": [Roles.ORGANIZER, Roles.PHOTOGRAPHER, Roles.REFEREE]
        },
        {
            "name": "Alyson",
            "user": "Alyson@hamable.com",
            "password": "google123",
            "role": [Roles.PHOTOGRAPHER]
        },
        {
            "name": "Maribel",
            "user": "maribel@hamable.com",
            "password": "google123",
            "role": [Roles.ORGANIZER, Roles.REFEREE, Roles.PHOTOGRAPHER]
        },
        {
            "name": "Astrid",
            "user": "astrid@hamable.com",
            "password": "google123",
            "role": [Roles.ORGANIZER]
        },
        {
            "name": "Oswaldo",
            "user": "ovgonzalez.4@icloud.com",
            "password": "google123",
            "role": [Roles.REFEREE]
        },
    ]

    const handleCredentials = ({ target: { value }}, key) =>
        setCredentials({ ...credentials, [key]: value })

    const logIn = () => {
        const session = credentialsList.filter(credential => credentials.user.toLowerCase() === credential.user)[0];

        if(session && credentials.password === session.password){
            setHelper({});
            localStorage.setItem("session", JSON.stringify(
                session
            ))

            window.location.assign("/#/ppg");
            window.scrollTo(0, 0);
        } else {
            setHelper({
                color: 'is-danger',
                text: 'Usuario o contraseña invalidos'
            })
        }
    }

    return (
        <>
            <BrandScreen child={
                <Title main={{text: 'Minuto a minuto, en vivo', type: 'title'}} secondary={{text: 'Tu evento deportivo, online...', type: 'subtitle'}}/>
            }></BrandScreen>

            <Hero
                body={
                <section className="container has-text-dark">
                    
                    <Card
                    style={{
                        margin:"auto",
                        maxWidth:"400px"
                    }}
                    content={
                        <>
                            <FormField
                                inputControlConfig={{
                                    inputConfig: {
                                        type: 'text',
                                        color: helper.color,
                                        onChange: (e) => handleCredentials(e, 'user')
                                    }
                                }}
                                labelText="Usuario"
                            />
                            <FormField
                                inputControlConfig={{
                                    inputConfig: {
                                        type: 'password',
                                        color: helper.color,
                                        onChange: (e) => handleCredentials(e, 'password')
                                    }
                                }}
                                labelText="Contraseña"
                                helperConfig={{
                                    text: helper.text,
                                }}
                            />

                            <Button
                                style={{ marginTop: "1rem", width:"100%"}}
                                color="is-info"
                                text="Iniciar Sesión"
                                onClick={logIn}
                            />
                        </>
                    }
                />

                </section>}
                size="is-fullheight"
                color='is-light'
            />
        </>
    );
}